import React, { useState, useEffect } from "react";
import CustomPinVerification from "../component/CustomePinVerification";
import { useLocation, Link, useHistory } from "react-router-dom";
import DUOlogo from '../assets/whiteduologo.svg';
import axiosCall from "../axios/axios";
import home from '../assets/home-icon.svg';
import { Modal, Button } from 'react-bootstrap';
import Loader from './Loader';
import ForgotPin from './ForgotPin';

const VerifyMontraPin = (props) => {
    const location = useLocation();
    const [modalOpen, setModalOpen] = useState(false);
    const [errormsg, setErrormsg] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [amountValue, setAmountValue] = useState();

    useEffect(() => {
        if (props !== undefined && props.location !== undefined && props.location.state !== undefined && props.location.state.repaymentamount !== undefined
            && props.location.state.getdata !== undefined && props.location.state.getcreditAccount !== undefined) {
            setAmountValue(props.location.state.repaymentamount);

        }
    }, [props]);

    let history = useHistory();
    const handleUpdateOTP = (newOTP) => {
        if (newOTP?.length === 4) {
            setIsLoading(true);
            const body = {
                "ACCOUNTNUMBER": localStorage.getItem("AccountNo"),
                "PIN": newOTP
            }
            axiosCall.POST('/customer/mobilepinverify', body)
                .then((response) => {
                    if (response.data.status === "200" && response.data.data.VALID_PIN === true) {

                        const body = {
                            "CREDITCARDSETTLEMENTAC": props.location.state.getcreditAccount,
                            "BANKACCOUNT": props.location.state.getdata,
                            "REPAYMENTAMOUNT": amountValue,
                            "CUSTOMERID": localStorage.getItem("customerid")
                        }
                        axiosCall.POST('/customer/repayment', body)
                            .then((response) => {
                                if (response.data.status === "200" && response.data.message === "SUCCESS") {
                                    history.push({
                                        pathname: '/Success',
                                        state: { amountValue }
                                    });
                                } else {
                                    setErrormsg(response.data.errordata.message);
                                    setModalOpen(true);
                                    setIsLoading(false);
                                }
                            })
                    }

                    else {
                        setErrormsg(response.data.data.MESSAGE);
                        setModalOpen(true);
                        setIsLoading(false);
                    }
                }).catch(err => {
                    console.log(err)
                    setErrormsg("Please try After Sometime");
                    setModalOpen(true);
                    setIsLoading(false);
                });
        }
    };

    const handleCloseModal = () => {
        setModalOpen(false);
    };
    const handleRedirect = () => {
        handleCloseModal();
        history.push('/Home');
    };


    return (
        <div>
            {/* <div onClick={() => Link(-1)}>
                <img src={BACK_ARROW} alt="montra-log" className={styles.backarrow} />
            </div> */}

            {modalOpen && (
                <Modal show={modalOpen} handleClose={handleCloseModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Alert</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p className='modaltext'>{errormsg}</p>
                        <hr className='hrline' />
                    </Modal.Body>

                    <Modal.Footer onClick={handleRedirect}>
                        <Button variant="secondary" >
                            OK
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}

            <div className='HeaderBgverify'>
                <div className="duoheaderveryfi">
                    <Link to="/Home"> <img className="duolog" src={DUOlogo} alt="logo" /></Link>
                </div>
                <p className='pinverifyheader'> PIN Verfication</p>
                <div className="duoheaderveryfihome">
                    <Link to="/Home">  <img className="duolog" src={home} alt="logo" /> </Link>
                </div>
            </div>
            {isLoading ? <div>
                <Loader />

                <CustomPinVerification
                    inputCount={4}
                    onUpdateOTP={handleUpdateOTP}
                    titleText={"Enter your Access Duo Pin"}
                    // forgotpinText={"Forgot PIN"}
                    description={true}
                    clearCode={errormsg}
                />
                <div className='forgototp'>
                    <ForgotPin />
                </div>
            </div> :
                <div>
                    <CustomPinVerification
                        inputCount={4}
                        onUpdateOTP={handleUpdateOTP}
                        titleText={"Enter your Access Duo Pin"}
                        // forgotpinText={"Forgot PIN"}
                        description={true}
                        clearCode={errormsg}
                    />
                    <div className='forgototp'>
                        <ForgotPin />
                    </div>
                </div>
            }
        </div>
    );
};

export default VerifyMontraPin;
