import React from 'react';
import DUOlogo from './assets/whiteduologo.svg';
import { Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import duomobile from './assets/duomobile.svg';
import duocard from './assets/duocard.svg';
import transactionhistory from './assets/transactionhistory.svg';
import home from './assets/home-yellow.svg';
import remap from './assets/remap.svg';




const Header2 = () => {


    return (
        <React.Fragment>
            <div>

                <Navbar className="navbar navbar-expand-lg navbar-dark bg-dark" bg="dark" expand="lg">
                    <div className="navi">

                        <ul className='ulnavicon'>
                            <li className='linavigation imgduologo'> <img className="duosublogotopmain" src={DUOlogo} alt="logo" /></li>

                            <Link to="/Home?page=Home">  <li className='linavigation'> <img className="duosublogotop " src={home} alt="logo" /></li> </Link>
                            <Link to="/VerifyDuoPin?page=VirtualCreditCard">  <li className='linavigation'> <img className="duosublogotop " src={duomobile} alt="logo" /></li> </Link>
                            <Link to="/VerifyDuoPin?page=TransactionHistory">   <li className='linavigation'> <img className="duosublogotop " src={transactionhistory} alt="logo" /></li> </Link>
                            <Link to="/VerifyDuoPin?page=RepaymentRequest">  <li className='linavigation'> <img className="duosublogotop " src={duocard} alt="logo" /></li></Link>
                            <Link to="/VerifyDuoPin?page=Remap">  <li className='linavigation remaplogo'> <img className="duosublogotop " src={remap} alt="logo" /></li></Link>
                            {/* <Link to="/PinVerification?page=ApplyDuoCard">  <li className='linavigation'> <img className="duosublogotop " src={cardnumber} alt="logo" /></li></Link>
                       */}
                        </ul>
                    </div>
                </Navbar>
            </div>
        </React.Fragment>
    )

}
export default Header2;