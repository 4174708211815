import React from 'react';
import DUOlogo from '../assets/whiteduologo.svg';
import { Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import duomobile from '../assets/duomobile.svg';
import duocard from '../assets/duocard.svg';
import transactionhistory from '../assets/transactionhistory-yellow.svg';
import { Tab, Tabs } from 'react-bootstrap';
import home from '../assets/home-icon.svg';
import axiosCall from '../axios/axios';
import remap from '../assets/remap.svg';
import Loader from './Loader';
import moment from 'moment';
import CardType from './CardType';
import { commaSeperator } from './CommaSeprator';



class TransactionHistory extends React.Component {

    constructor(props) {
        super()
        this.state = {
            fields: {
                pin: '',
                accountNumber: '',
                bankcode: ''
            },

            darkBtn: "btn1",
            darkBtnDr: "btn7",
            dataDr: [],
            dataCr: [],
            dataRe: [],
            subtractDays: 7,
            subtractDay: 90,
            isSubmitClicked: true,
            isLoading: false,
            transactionerror: '',
            responseDataTab1: null,
            responseDataTab2: null,
            activeTab: 'tab1'

        }

        this.handle7Days = this.handle7Days.bind(this, "btn7");
        this.handle15Days = this.handle15Days.bind(this, "btn8");
        this.handle30Days = this.handle30Days.bind(this, "btn9");
        this.handle60Days = this.handle60Days.bind(this, "btn10");
        this.handle90Days = this.handle90Days.bind(this, "btn11");
        this.handle90DaysCredit = this.handle90DaysCredit.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleSubmitCredit = this.handleSubmitCredit.bind(this);
        this.handleTabSelect = this.handleTabSelect.bind(this);

    }


    addDarkClassDr = (btn6) => {
        if (this.state.darkBtnDr === btn6) return "blackButton";
        else return "whiteButton";
    };

    handle7Days(btn6) {
        this.setState({
            subtractDays: 7
        })
        this.setState({ darkBtnDr: btn6 });
        setTimeout(() => {
            this.handleSubmit();
        }, 10);
    }

    handle15Days(btn6) {
        this.setState({
            subtractDays: 15
        })
        this.setState({ darkBtnDr: btn6 });
        setTimeout(() => {
            this.handleSubmit();
        }, 10);
    }

    handle30Days(btn6) {
        this.setState({
            subtractDays: 30
        })
        this.setState({ darkBtnDr: btn6 });
        setTimeout(() => {
            this.handleSubmit();
        }, 10);
    }

    handle60Days(btn6) {
        this.setState({
            subtractDays: 60
        })
        this.setState({ darkBtnDr: btn6 });
        setTimeout(() => {
            this.handleSubmit();
        }, 10);
    }

    handle90Days(btn6) {
        this.setState({
            subtractDays: 90
        })
        this.setState({ darkBtnDr: btn6 });
        setTimeout(() => {
            this.handleSubmit();
        }, 10);
    }

    handle90DaysCredit() {
        this.setState({
            subtractDays: 90,
        })
        setTimeout(() => {
            this.handleSubmitCredit();
        }, 10);
    }


    handleTabSelect = (tab) => {
        this.setState({ activeTab: tab });
        if (tab === "tab1") {
            this.handleSubmit();
        } else if (tab === "tab2") {
            this.handleSubmitCredit();
        }
    };

    handleSubmitCredit = async () => {
        this.setState({
            transactionerror: '',
            isLoading: true
        });

        const startingDate = new Date();
        const endingDate = new Date();
        endingDate.setDate(endingDate.getDate() - this.state.subtractDays);
        const endingDateNew = new Date();
        endingDateNew.setDate(endingDateNew.getDate() - this.state.subtractDay);

        try {
            const crResponse = await axiosCall.POST('/customer/transactionhistorycr', {
                "ACCOUNTNUMBER": localStorage.getItem("AccountNo"),
                STARTDATE: endingDateNew.toISOString().split('T')[0],
                ENDTDATE: startingDate.toISOString().split('T')[0],
                "CREDITACCOUNTNUMBER": localStorage.getItem("CREDITACCOUNTNUMBER"),
            });

            if (crResponse.status === 200) {
                if (crResponse.data.message === 'SUCCESS') {
                    this.setState({
                        dataCr: crResponse.data.data.Transactions,
                        dataRe: crResponse.data.data.RecentTransactions,
                        transactionerror: "There are no Repayments found from last 90 Days"
                    });
                } else {
                    // Handle other cases
                }
            }
        } catch (error) {
            console.log(error);
        }

        this.setState({ isLoading: false });
    };


    handleSubmit() {
        this.setState({
            transactionerror: ''
        })
        this.setState({ isLoading: true });
        const startingDate = new Date();
        const endingDate = new Date();
        endingDate.setDate(endingDate.getDate() - this.state.subtractDays);
        const endingDateNew = new Date();
        endingDateNew.setDate(endingDateNew.getDate() - this.state.subtractDay);

        Promise.all([
            axiosCall.POST('/customer/transactionhistorydr', {
                "ACCOUNTNUMBER": localStorage.getItem("AccountNo"),
                STARTDATE: endingDate.toISOString().split('T')[0],
                ENDTDATE: startingDate.toISOString().split('T')[0],
            })
        ]).then((responses) => {
            const drResponse = responses[0];
            if (drResponse.status === 200) {
                if (drResponse.data.message === 'SUCCESS') {
                    this.setState({
                        dataDr: drResponse.data.data.Transactions,
                        transactionerror: "There are no Transactions in the period you have selected",
                    });
                    this.setState({ isLoading: false });
                } else {

                }
            }
        }).catch((error) => {
            console.log(error);
        });
    }

    componentDidMount() {
        this.handleSubmit();

    }

    render() {
        const { isLoading } = this.state;
        const { activeTab } = this.state;
        return (
            <React.Fragment>

                <Navbar className="navbar navbar-expand-lg navbar-dark bg-dark" bg="dark" expand="lg">
                    <div className="navi">
                        <ul className='ulnavicon'>
                            <li className='linavigation imgduologo'> <img className="duosublogotopmain" src={DUOlogo} alt="logo" /></li>
                            <Link to="/Home">  <li className='linavigation'> <img className="duosublogotop " src={home} alt="logo" /></li> </Link>
                            <Link to="/VerifyDuoPin?page=VirtualCreditCard">  <li className='linavigation'> <img className="duosublogotop " src={duomobile} alt="logo" /></li> </Link>
                            <Link to="/TransactionHistory">   <li className='linavigation'> <img className="duosublogotop " src={transactionhistory} alt="logo" /></li> </Link>
                            <Link to="/VerifyDuoPin?page=RepaymentRequest">  <li className='linavigation'> <img className="duosublogotop " src={duocard} alt="logo" /></li></Link>
                            <Link to="/VerifyDuoPin?page=Remap">  <li className='linavigation remaplogo'> <img className="duosublogotop " src={remap} alt="logo" /></li></Link>
                            {/* <Link to="/PinVerification?page=ApplyDuoCard">  <li className='linavigation'> <img className="duosublogotop " src={cardnumber} alt="logo" /></li></Link> */}

                        </ul>
                    </div>
                </Navbar>
                <p className='homesummery'>Transaction History</p>
                < CardType />
                {isLoading ?

                    <div className='container history-container'>

                        <div className='row'>
                            <div className='col-md-3'>
                            </div>
                            <div className='col-md-6'>
                                <div className=''>
                                    <Tabs activeKey={activeTab} id="uncontrolled-tab-example" onSelect={this.handleTabSelect}>
                                        <Tab id="chevron" eventKey="tab1" title="Spends (Debit Transactions)"  >

                                            <div className='register'>
                                                <p className='historytext'>DUO Credit Card Number :  <span className='textbold'> {localStorage.getItem("CreditCardNo")} </span></p>
                                            </div>
                                            <div className="d-flex flex-row mb-3 transparent">
                                                <div className="p-2 bd-highlight activemtd1 act1">
                                                    <button className={this.addDarkClassDr("btn7")} onClick={this.handle7Days}>Last 7 Days</button>
                                                </div>

                                                <div className="p-2 bd-highlight activemtd1">
                                                    <button className={this.addDarkClassDr("btn8")} onClick={this.handle15Days}>Last 15 Days</button>
                                                </div>

                                                <div className="p-2 bd-highlight activemtd1">
                                                    <button className={this.addDarkClassDr("btn9")} onClick={this.handle30Days}>Last 30 Days</button>
                                                </div>

                                                <div className="p-2 bd-highlight activemtd1">
                                                    <button className={this.addDarkClassDr("btn10")} onClick={this.handle60Days}>Last 60 Days</button>
                                                </div>

                                                <div className="p-2 bd-highlight activemtd1 act2">
                                                    <button className={this.addDarkClassDr("btn11")} onClick={this.handle90Days}>Last 90 Days</button>
                                                </div>
                                            </div>
                                            <Loader />
                                            {(() => {
                                                if (this.state.dataDr.length > 0) {
                                                    return (
                                                        <table>
                                                            <tr>
                                                                <th>Date</th>
                                                                <th>Time</th>
                                                                <th>Description</th>
                                                                <th>Amount</th>
                                                            </tr>

                                                            <tbody>
                                                                {this.state.dataDr.map(Transactions => {
                                                                    const dateFormats = ['D/M/YYYY'];
                                                                    const transactionDate = moment.utc(Transactions.TRANSACTIONDATE, dateFormats, true);

                                                                    const formattedDate = transactionDate.isValid() ? transactionDate.format('DD/MM/YYYY') : Transactions.TRANSACTIONDATE;

                                                                    return (
                                                                        <tr key={Transactions.id}>
                                                                            <td>{formattedDate.split('/').map(d => d.padStart(2, '0')).join('/')}</td>
                                                                            <td>{Transactions.TRANSACTIONTIME.substring(0, 5)}</td>
                                                                            <td>{Transactions.DESCRIPTION}</td>
                                                                            <td>₦{commaSeperator(Transactions.AMOUNT, 2)}</td>
                                                                        </tr>
                                                                    );
                                                                })}
                                                            </tbody>

                                                        </table>
                                                    )
                                                } else {
                                                    return (
                                                        <div>
                                                            <p className="errorpin">{this.state.transactionerror}</p>

                                                        </div>)
                                                }
                                            })()}
                                        </Tab>


                                        <Tab id="chevrons" eventKey="tab2" title="Repayments (Last 90 Days)">
                                            <Loader />
                                            <div className='register'>
                                                <p className='historytext'>DUO Credit Card Number :  <span className='textbold'>{localStorage.getItem("CreditCardNo")} </span></p>
                                            </div>

                                            {(() => {
                                                if (this.state.dataCr.length > 0) {
                                                    return (
                                                        <table>
                                                            <tr>
                                                                <th>Date</th>
                                                                <th>Time</th>
                                                                <th>Description</th>
                                                                <th>Amount</th>
                                                            </tr>
                                                            <tbody>
                                                                {this.state.dataCr.map(Transactions => {
                                                                    const dateFormats = ['D/M/YYYY'];
                                                                    const transactionDate = moment.utc(Transactions.TRANSACTIONDATE, dateFormats, true);

                                                                    const formattedDate = transactionDate.isValid() ? transactionDate.format('DD/MM/YYYY') : Transactions.TRANSACTIONDATE;

                                                                    return (
                                                                        <tr key={Transactions.id}>
                                                                            <td>{formattedDate.split('/').map(d => d.padStart(2, '0')).join('/')}</td>
                                                                            <td>{Transactions.TRANSACTIONTIME.substring(0, 5)}</td>
                                                                            <td>{Transactions.DESCRIPTION}</td>
                                                                            <td>₦{commaSeperator(Transactions.AMOUNT, 2)}</td>
                                                                        </tr>
                                                                    );
                                                                })}
                                                            </tbody>
                                                        </table>
                                                    )

                                                } else {
                                                    return (
                                                        <div>
                                                            <p className="errorpin">{this.state.transactionerror}</p>
                                                        </div>)
                                                }
                                            })()}
                                        </Tab>
                                    </Tabs>

                                </div>
                            </div>
                            <div className='col-md-3'>
                            </div>
                        </div>

                    </div> :
                    <div className='container history-container'>
                        <div className='row'>
                            <div className='col-md-3'>
                            </div>
                            <div className='col-md-6 transactioncolumns'>

                                {/* <div className="container-listitem ">
                                    <div className="listitemrepayment">
                                        <img className="duologohome" src={Access} alt="logo" />
                                        <p className="accessicon"> Access Bank</p>
                                    </div>
                                    <div className="listitemrepayment1">
                                    </div>
                                    <div className="listitemrepayment2">
                                        <img className="duologohome" src={Platinum} alt="logo" />
                                        <p className="accessicon1"> Platinum</p>
                                    </div>
                                </div> */}
                                {/* <hr className='hrline' /> */}


                                <div className=''>
                                    <Tabs activeKey={activeTab} id="uncontrolled-tab-example" onSelect={this.handleTabSelect} >
                                        <Tab id="chevron" eventKey="tab1" title="Spends (Debit Transactions)">

                                            <div className='register'>
                                                <p className='historytext'>DUO Credit Card Number :  <span className='textbold'> {localStorage.getItem("CreditCardNo")} </span></p>
                                            </div>
                                            <div className="d-flex flex-row mb-3 transparent">
                                                <div className="p-2 bd-highlight activemtd1 act1">
                                                    <button className={this.addDarkClassDr("btn7")} onClick={this.handle7Days}>Last 7 Days</button>
                                                </div>

                                                <div className="p-2 bd-highlight activemtd1">
                                                    <button className={this.addDarkClassDr("btn8")} onClick={this.handle15Days}>Last 15 Days</button>
                                                </div>

                                                <div className="p-2 bd-highlight activemtd1">
                                                    <button className={this.addDarkClassDr("btn9")} onClick={this.handle30Days}>Last 30 Days</button>
                                                </div>

                                                <div className="p-2 bd-highlight activemtd1">
                                                    <button className={this.addDarkClassDr("btn10")} onClick={this.handle60Days}>Last 60 Days</button>
                                                </div>

                                                <div className="p-2 bd-highlight activemtd1 act2">
                                                    <button className={this.addDarkClassDr("btn11")} onClick={this.handle90Days}>Last 90 Days</button>
                                                </div>
                                            </div>

                                            {(() => {
                                                if (this.state.dataDr.length > 0) {
                                                    return (
                                                        <table>
                                                            <tr>
                                                                <th>Date</th>
                                                                <th>Time</th>
                                                                <th>Description</th>
                                                                <th>Amount</th>
                                                            </tr>

                                                            <tbody>
                                                                {this.state.dataDr.map(Transactions => {
                                                                    const dateFormats = ['D/M/YYYY'];
                                                                    const transactionDate = moment.utc(Transactions.TRANSACTIONDATE, dateFormats, true);

                                                                    const formattedDate = transactionDate.isValid() ? transactionDate.format('DD-MM-YYYY') : Transactions.TRANSACTIONDATE;

                                                                    return (
                                                                        <tr key={Transactions.id}>
                                                                            <td>{formattedDate.split('/').map(d => d.padStart(2, '0')).join('/')}</td>
                                                                            <td>{Transactions.TRANSACTIONTIME.substring(0, 5)}</td>
                                                                            <td>{Transactions.DESCRIPTION}</td>
                                                                            <td>₦{commaSeperator(Transactions.AMOUNT, 2)}</td>
                                                                        </tr>
                                                                    );
                                                                })}
                                                            </tbody>
                                                        </table>
                                                    )

                                                } else {
                                                    return (
                                                        <div>
                                                            <p className="errorpin">{this.state.transactionerror}</p>
                                                        </div>)
                                                }
                                            })()}
                                        </Tab>


                                        <Tab id="chevrons" eventKey="tab2" title="Repayments (Last 90 Days)">
                                            <div className='register'>
                                                <p className='historytext'>DUO Credit Card Number :  <span className='textbold'>{localStorage.getItem("CreditCardNo")} </span></p>
                                            </div>
                                            {(() => {
                                                if (this.state.dataCr.length > 0) {
                                                    return (
                                                        <div>
                                                            <table>
                                                                <tr>
                                                                    <th>Date</th>
                                                                    <th>Time</th>
                                                                    <th>Description</th>
                                                                    <th>Amount</th>
                                                                </tr>

                                                                <tbody>
                                                                    {this.state.dataCr.map(Transactions => {
                                                                        const dateFormats = ['D/M/YYYY'];
                                                                        const transactionDate = moment.utc(Transactions.TRANSACTIONDATE, dateFormats, true);

                                                                        const formattedDate = transactionDate.isValid() ? transactionDate.format('DD-MM-YYYY') : Transactions.TRANSACTIONDATE;

                                                                        return (
                                                                            <tr key={Transactions.id}>
                                                                                <td>{formattedDate.split('/').map(d => d.padStart(2, '0')).join('/')}</td>
                                                                                <td>{Transactions.TRANSACTIONTIME.substring(0, 5)}</td>
                                                                                <td>{Transactions.DESCRIPTION}</td>
                                                                                {/* <td>₦{parseFloat(Transactions.AMOUNT).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td> */}
                                                                                <td>₦{commaSeperator(Transactions.AMOUNT, 2)}</td>
                                                                            </tr>
                                                                        );
                                                                    })}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    )
                                                } else {
                                                    return (
                                                        <div>
                                                            <p className="errorpin">{this.state.transactionerror}</p>
                                                        </div>)
                                                }
                                            })()}

                                            {(() => {
                                                if (this.state.dataRe.length > 0) {
                                                    return (
                                                        <div>
                                                            <p className='RecentRepayment'>Recent Repayments (Last 7 Days)</p>
                                                            <table>
                                                                <tr>
                                                                    <th>Date</th>
                                                                    <th>Time</th>
                                                                    <th>Description</th>
                                                                    <th>Amount</th>
                                                                </tr>

                                                                <tbody>
                                                                    {this.state.dataRe.map(RecentTransactions => {
                                                                        const dateFormats = ['D/M/YYYY'];
                                                                        const transactionDate = moment.utc(RecentTransactions.TRANSACTIONDATE, dateFormats, true);

                                                                        const formattedDate = transactionDate.isValid() ? transactionDate.format('DD-MM-YYYY') : RecentTransactions.TRANSACTIONDATE;

                                                                        return (
                                                                            <tr key={RecentTransactions.id}>
                                                                                <td className='recentTrans'>{formattedDate.split('/').map(d => d.padStart(2, '0')).join('/')}</td>
                                                                                <td className='recentTrans'>{RecentTransactions.TRANSACTIONTIME.substring(0, 5)}</td>
                                                                                <td className='recentTrans'>{RecentTransactions.DESCRIPTION}</td>
                                                                                {/* <td>₦{parseFloat(Transactions.AMOUNT).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td> */}
                                                                                <td className='recentTrans'>₦{commaSeperator(RecentTransactions.AMOUNT, 2)}</td>
                                                                            </tr>
                                                                        );
                                                                    })}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    )
                                                } else {
                                                    return (
                                                        <div>
                                                            <p className='RecentRepayment'>Recent Repayments (Last 7 Days)</p>
                                                            <p className="errorpin">There are no Recent Repayment Found.</p>
                                                        </div>)
                                                }
                                            })()}
                                        </Tab>
                                    </Tabs>
                                </div>
                            </div>
                            <div className='col-md-3'>
                            </div>
                        </div>
                    </div>
                }

            </React.Fragment>
        )
    }
}
export default TransactionHistory;