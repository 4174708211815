import React, { useEffect, useState } from "react";
import styles from "./customotpverification.module.css";
import TextStyle from "../TextStyle/textStyle";
// import { VERIFYED_ICON } from "../../assets/accessbank.png";

const CustomPinVerification = ({
  inputCount,
  onUpdateOTP,
  titleText,
  forgotpinText,
  description,
  inputStyle,
  pindescription,
  verifyedlogo,
  clearCode
}) => {
  const [otp, setOTP] = useState(
    inputCount === 4 ? ["", "", "", ""] : ["", "", "", "", "", ""]
  );

  useEffect(() => {
    onUpdateOTP(otp.join(""));
  }, [otp]);

  useEffect(() => {
    clearCode && setOTP(inputCount === 4 ? ["", "", "", ""] : ["", "", "", "", "", ""])
  }, [clearCode])

  const handleButtonClick = (buttonValue) => {
    const updatedOTP = [...otp];
    const emptyIndex = updatedOTP.indexOf("");
    if (emptyIndex !== -1) {
      updatedOTP[emptyIndex] = buttonValue;
      setOTP(updatedOTP);
    }
  };

  // const otpCount = [...Array(inputCount)];

  const handleBackspace = () => {
    const newOtp = [...otp];
    for (let i = 3; i >= 0; i--) {
      if (newOtp[i] !== "") {
        newOtp[i] = "";
        break;
      }
    }
    setOTP(newOtp);
  };

  const numberArray = [1, 2, 3, 4, 5, 6, 7, 8, 9, 0, "Clear"];

  return (
    <div style={{ color: '#fff' }}>
      <div className={styles.otpkeypadcontainer} >

        <TextStyle
          label={titleText}
          containerStyle={styles.headerContainer}
          textStyle={styles.headerText}
        />
        {verifyedlogo && (
          <div style={{ display: "flex", marginLeft: "50px" }}>
            <p className={styles.poweredby_text}>Powerd by</p>
            {/* <img
              src={VERIFYED_ICON}
              alt="verifyedicon"
              className={styles.verifyedicon}
            /> */}
          </div>
        )}
        <div className={styles.otpinput}>
          {otp.map((digit, index) => (
            <input
              key={index}
              type="text"
              value={digit}
              readOnly
              className={inputStyle ? inputStyle : styles.otpinputbox}
              style={
                digit
                  ? {
                    background: "#ffc000",
                    color: "#ffc000",
                    border: "2px solid #ffc000",
                  }
                  : {}
              }
            />
          ))}
        </div>
        {pindescription && (
          <TextStyle
            label={pindescription}
            containerStyle={styles.infoContainer}
            textStyle={styles.infoText}
          />
        )}
        <div>
          <div className={styles.gridcontainer}>
            {numberArray?.map((buttonValue, i) => {
              return (
                <div
                  className={`${styles.griditem} ${numberArray.length === i + 1
                    ? styles.lastNumberBox
                    : numberArray.length - 2 === i
                      ? styles.zeroNumberBox
                      : ""
                    }`}
                  key={buttonValue}
                  onClick={() => {
                    if (buttonValue === "Clear") {
                      // setOTP(["", "", "", ""]);
                      handleBackspace();
                    } else {
                      handleButtonClick(buttonValue.toString());
                    }
                  }}
                >
                  {buttonValue}
                </div>
              );
            })}
          </div>

          <TextStyle
            label={forgotpinText}
            containerStyle={styles.linkContainer}
            textStyle={styles.linkText}
          />
        </div>
        {description && (
          <TextStyle
            // label="Please reset it on Montra App and try linking again on MSC app"
            containerStyle={styles.infoContainer}
            textStyle={styles.infoText}
          />
        )}
      </div>
    </div>
  );
};

export default CustomPinVerification;
