import React from 'react';
import DUOlogo from '../assets/whiteduologo.svg';


const Loader = () => {
    return (
      <div>
           <div className='HeaderBg'>
                <div className="duoheader">
                <img className="duolog" src={DUOlogo} alt="logo" />
            </div>
            </div>
            <p className='homesummery1'></p>
      
      <div className="loader">
       <p>User is not Authorised</p> 
      </div>
      </div>
    );
  }

  export default Loader;