
import React from "react";
import styles from "./style.module.css";

const TextStyle = ({
  label,
  containerStyle,
  textStyle,
}) => {
  return (
    <div className={containerStyle}>
      <h5
        className={`${styles.textStyle} ${textStyle}`}
      >
        {label}</h5>
    </div>
  );
};

export default TextStyle;
