import React, { useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import Header from '../Header';


const RegistrationPage = () => {
    const [checked, setChecked] = useState("NO");
    const [error, setError] = useState('');

    const handleCheckboxChange = (event) => {
        const newValue = event.target.checked ? 'YES' : 'NO';
        setChecked(newValue);
    };
    let history = useHistory();
    const handleSubmit = (event) => {
        event.preventDefault();
        if (checked === "NO") {
            setError('Please accept terms & conditions');

        } else {
            history.push({
                pathname: "/Pin",
            });
        }
    };
    const creditLimit = Number(localStorage.getItem("ActualLimit"));
    const formattedCreditLimit = creditLimit.toLocaleString('en-NG');
    return (
        <React.Fragment>
            <Header />
            <div className='container'>
                <div className='row'>
                    <div className='col-md-3'>
                    </div>
                    <div className='col-md-6'>
                        <div className='registertop'>
                            <p className='registerscreen'>Your Debit Card linked to your bank account <span className='textbold'> {"XXXXXXXXXX" + localStorage.getItem("AccountNo").slice(0).repeat(0)} </span> has been successfully verified</p>
                        </div>
                        <div>
                            <p className='approvedtext'><span className='textbold'>Pre-approved Credit Limit</span></p>
                            <p className='naira'>₦  {formattedCreditLimit}</p>
                        </div>
                        <div className='registertop-agree'>
                            <p className='termscondition'>Please agree on following T&C for issuance of DUO Credit Card </p>
                            <ul className='ulist'>
                                <li>Issuance Fee <span className='textbold'> + VAT - ₦ 1075 </span></li>
                                <li>Quarterly Maintenance Fee <span className='textbold'> + VAT - ₦ 53.75/quarter </span></li>
                                <li>Minimum monthly repayments to be paid - <span className='textbold'>10% </span> of outstanding amount.</li>
                                <li> <span className='textbold'>8% </span>Interest charged monthly on revolving balance.</li>
                                <li>You agree to pay the outstanding amount from your BVN linked accounts.</li>
                                <li>Annual Insurance premium on the credit card – 0.9% of the credit limit.</li>
                            </ul>
                            <div className='termshere'>
                                <p className='termscondition'>You can view the detailed terms here <br />
                                    <span className='textbold'>
                                        <a href="https://www.accessbankplc.com/credit-cards-terms.aspx" target="_blank" rel="noopener noreferrer" className='hyperlink'>
                                            https://www.accessbankplc.com/credit-cards-terms.aspx
                                        </a>
                                    </span></p>
                            </div>
                            <form className='formsubmitregistration' onSubmit={handleSubmit}>
                                <div className='applyduotextregfirst'>
                                    <label className="custom-checkbox">
                                        <input type="checkbox" checked={checked === 'YES'} onChange={handleCheckboxChange} />
                                        <span className="checkmark"></span>
                                        <span className="checkbox-label2">I agree the above terms & conditions. Please issue me a DUO Credit Card
                                        </span>
                                    </label>

                                </div>
                                {error && <div className='pinerror termserror'>{error}</div>}
                                <br />
                                <div className='register-button'>
                                    <input type="submit" className="btn btn-warning nextbuttonpinreg" value="Next" />
                                </div>
                            </form>
                            {/* <div className='register-button'>
                                <Link to="/Pin"><button className='nextbutton'>NEXT</button></Link>
                            </div> */}
                        </div>
                        <div className='col-md-3'>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )

}
export default RegistrationPage;