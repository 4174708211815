import React from 'react';
import { Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import duomobile from '../assets/duomobile.svg';
import duocard from '../assets/duocard.svg';
import transactionhistory from '../assets/transactionhistory.svg';
import home from '../assets/home-icon.svg';
import DUOlogo from '../assets/whiteduologo.svg';
import remap from '../assets/remap1.svg';



const RemapSuccess = (props) => {

    return (
        <React.Fragment>
            <Navbar className="navbar navbar-expand-lg navbar-dark bg-dark" bg="dark" expand="lg">
                <div className="navi">
                    <ul className='ulnavicon'>
                        <li className='linavigation imgduologo'> <img className="duosublogotopmain" src={DUOlogo} alt="logo" /></li>
                        <Link to="/Home">  <li className='linavigation'> <img className="duosublogotop " src={home} alt="logo" /></li> </Link>
                        <Link to="/VerifyDuoPin?page=VirtualCreditCard">  <li className='linavigation'> <img className="duosublogotop " src={duomobile} alt="logo" /></li> </Link>
                        <Link to="/VerifyDuoPin?page=TransactionHistory">   <li className='linavigation'> <img className="duosublogotop " src={transactionhistory} alt="logo" /></li> </Link>
                        <Link to="/VerifyDuoPin?page=RepaymentRequest">  <li className='linavigation'> <img className="duosublogotop " src={duocard} alt="logo" /></li></Link>
                        <Link to="/VerifyDuoPin?page=Remap">  <li className='linavigation remaplogo'> <img className="duosublogotop " src={remap} alt="logo" /></li></Link>
                        {/* <Link to="/VerifyDuoPin?page=ApplyDuoCard">  <li className='linavigation'> <img className="duosublogotop " src={cardnumber} alt="logo" /></li></Link>                  
                        */}
                    </ul>
                </div>
            </Navbar>
            <p className='homesummery'>View / Update Linked Debit Cards (DTS)</p>
            <div className='container remapview'>
                <div className='row'>
                    <div className='col-md-3'>
                    </div>
                    <div className='col-md-6'>
                        <div className="container-listitem remap-text">
                            <div className="listitemremap">
                                <p className='remaplist'> Virtual Credit Card Number</p>
                            </div>

                            <div className="listitemremap1">
                                <p className='remaplist'>{props.location.state.creditStatusData[0].CREDITCARD.PAN}</p>
                            </div>
                        </div>

                        <div className="container-listitem Remapdiv ">
                            <div className="listitemremap">
                                <p className='remaplist'> Bank Account Number</p>
                            </div>

                            <div className="listitemremap1">
                                <p className='remaplist'>{"XXXXXXX" + props.location.state.creditStatusData[0].ACCOUNT.slice(-4).repeat(1)}</p>
                            </div>
                        </div>
                        <p className="remapdebitnew2">Following is the updated list of all Debit cards that are now linked to your virtual Credit Card.</p>
                        <ul className='listremap'>

                            {props.location.state.panlistData.map((item) => (
                                <li className='listremap1'>{item.MASKEDPAN}</li>
                            ))}

                        </ul>

                        <div className='register-button'>
                            <Link to='/Home'><button className="btn btn-warning nextbuttonpinRey"> Done </button> </Link>

                        </div>

                    </div>
                    <div className='col-md-3'>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )

}
export default RemapSuccess;