import React, { useEffect, useState } from 'react';
import DUOlogo from '../assets/whiteduologo.svg';
import { Navbar } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import duomobile from '../assets/duomobile.svg';
import duocard from '../assets/duocard-yellow.svg';
import cardnumber from '../assets/cardnumber.svg';
import { useForm } from 'react-hook-form';
import transactionhistory from '../assets/transactionhistory.svg';
import home from '../assets/home-icon.svg';
import axiosCall from '../axios/axios';
import remap from '../assets/remap.svg';
import Loader from './Loader';
import CardType from './CardType';
import { Modal, Button } from 'react-bootstrap';
import { commaSeperator } from './CommaSeprator';



const RepaymentRequest = (props) => {

    const { setValue, handleSubmit, formState: { errors } } = useForm();
    const [formattedValue, setFormattedValue] = useState();
    const [getdata, setGetdata] = useState([]);
    const [getcreditAccount, setGetcreditAccount] = useState([]);
    const [errormsg, setErrormsg] = useState('');
    const [repaymentamount, setRepaymentamount] = useState('');
    const [creditStatusData, setCreditStatusData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [error, setError] = useState('');
    const creditLimit = Number(localStorage.getItem("ActualLimit"));
    const formattedCreditLimit = creditLimit.toLocaleString('en-NG');
    const [availableBalance, setAvailableBalance] = useState();

    const handleInputChange = (e) => {
        let value = e.target.value;
        value = value.replace(/[^\d.]/g, "");
        const decimalCount = value.split('.').length - 1;
        if (decimalCount > 2) {
            value = value.slice(0, value.lastIndexOf('.'));
        }
        const formatted = formatNumberWithCommas(value);
        setFormattedValue(formatted);
        const formattedAmount = e.target.value.replace(/,/g, '');
        setRepaymentamount(formattedAmount);
        if (value === '') {
            setValue("amount", null);
        } else {
            setValue("amount", parseFloat(value));
        }
        setError('');
    };
    const formatNumberWithCommas = (value) => {
        const parts = value.toString().split('.');
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        return parts.join('.');
    };
    useEffect(() => {
        setIsLoading(true);
        const fetchCustomerCreditStatus = axiosCall.POST('/customer/customercreditstatus', { "ACCOUNTNUMBER": localStorage.getItem("AccountNo") })
            .then(response => {
                if (response.data.status === "200" && response.data.message === "SUCCESS") {
                    setGetdata(response.data.data.ACCOUNTNUMBER);
                    setGetcreditAccount(response.data.data.CREDITACCOUNT);
                    setCreditStatusData([response.data.data]);
                }
            }).catch(err => {
                console.error(err);
            });
        const fetchDuoCustomerInfo = axiosCall.POST('/customer/getduocustomerinfo', { "ACCOUNTNUMBER": localStorage.getItem("AccountNo") })
            .then(response => {
                if (response.data.status === "200" && response.data.message === "SUCCESS") {
                    setAvailableBalance([response?.data?.data?.AVAILABLE_BALANCE]);
                }
            }).catch(err => {
                console.error(err);
            });
        Promise.all([fetchCustomerCreditStatus, fetchDuoCustomerInfo])
            .then(() => setIsLoading(false))
            .catch(err => {
                console.error(err);
                setIsLoading(false);
            });
    }, []);
    let history = useHistory();
    const onSubmit = async (data) => {
        const roundedBalance = (Math.abs(creditStatusData[0].CREDIT_CARD_OUTSTANDINGBALANCE));
        const maxValue = data.amount
        if (maxValue == null) {
            setError('Please enter an Amount');
        } else if (maxValue > roundedBalance) {
            setError('Repayment amount must be less than the total Credit Card outstanding');
        }
        else {
            history.push({
                pathname: "/RePaymentPinVerification",
                state: { repaymentamount, getdata, getcreditAccount }
            });
        }
    }
    const handleCloseModal = () => {
        setModalOpen(false);
    };
    const handleRedirect = () => {
        handleCloseModal();
    };
    return (
        <React.Fragment>
            <Navbar className="navbar navbar-expand-lg navbar-dark bg-dark" bg="dark" expand="lg">
                <div className="navi">
                    <ul className='ulnavicon'>
                        <li className='linavigation imgduologo'> <img className="duosublogotopmain" src={DUOlogo} alt="logo" /></li>
                        <Link to="/Home">  <li className='linavigation'> <img className="duosublogotop " src={home} alt="logo" /></li> </Link>
                        <Link to="/VerifyDuoPin?page=VirtualCreditCard">  <li className='linavigation'> <img className="duosublogotop " src={duomobile} alt="logo" /></li> </Link>
                        <Link to="/VerifyDuoPin?page=TransactionHistory">   <li className='linavigation'> <img className="duosublogotop " src={transactionhistory} alt="logo" /></li> </Link>
                        <Link to="/RepaymentRequest">  <li className='linavigation'> <img className="duosublogotop " src={duocard} alt="logo" /></li></Link>
                        <Link to="/VerifyDuoPin?page=Remap">  <li className='linavigation remaplogo'> <img className="duosublogotop " src={remap} alt="logo" /></li></Link>
                        {/* <Link to="/VerifyDuoPin?page=ApplyDuoCard">  <li className='linavigation'> <img className="duosublogotop " src={cardnumber} alt="logo" /></li></Link>
                        */}
                    </ul>
                </div>
            </Navbar>
            <p className='homesummery'>Repayment Request</p>
            <div>
                {modalOpen && (
                    <Modal show={modalOpen} handleClose={handleCloseModal}>
                        <Modal.Header closeButton>
                            <Modal.Title>Alert</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p className='modaltext'>{errormsg}</p>
                            <hr className='hrline' />
                        </Modal.Body>
                        <Modal.Footer onClick={handleRedirect}>
                            <Button variant="secondary"  >
                                OK
                            </Button>
                        </Modal.Footer>
                    </Modal>
                )}
            </div>
            <CardType />
            {isLoading ? <div>
                <Loader className="loadertext" />
                <div className=''>
                    <div className=''>
                        <div className=''>
                        </div>
                        <div className=''>
                            <div>
                                {creditStatusData && <div>
                                    {creditStatusData.map((item, i) => (
                                        <div>
                                            <div className='repayment-box'>
                                                <p className='creditdetails'>Credit Card Details</p>
                                                <div className='flowlist'>
                                                    <ul className='credit'>
                                                        <li className='creditdetails1'><span>DUO Credit Card Number</span> </li>
                                                        <li className='creditdetails1'><span>Credit Limit</span> </li>
                                                        <li className='creditdetails1'><span>Total Credit Card outstanding</span></li>
                                                        <li className='creditdetails1 avail'><span>Available Balance</span></li>
                                                    </ul>
                                                    <ul className='credit creditlistflow'>
                                                        <li className='creditdetails1'> <span className="creditbold"> : {item.CREDITCARD}</span></li>
                                                        <li className='creditdetails1'> <span className="creditbold"> : ₦ {commaSeperator(item.CREDIT_LIMIT, 2)}</span></li>
                                                        <li className='creditdetails1'><span className="creditbold"> : ₦ {commaSeperator(Math.abs(item.CREDIT_CARD_OUTSTANDINGBALANCE), 2)} </span> </li>
                                                        <li className='creditdetails1 avail'><span className="creditbold">:  ₦ {commaSeperator(availableBalance, 2)} </span></li>
                                                    </ul>
                                                </div>
                                                <div className='flowlist1'>
                                                    <ul className='creditminimum'>
                                                        <li className='duepayment'><span>Minimum Due Repayment</span> </li>
                                                    </ul>
                                                    <ul className='creditminimum'>
                                                        <li className='duepayment1'><span>: ₦ {commaSeperator(Math.abs(item.MINIMUM_REPAYMENT_DUE), 2)}</span> </li>
                                                    </ul>
                                                </div>
                                                {/* <p className='creditdetails2'>Minimum Due Repayment :<span className="creditboldyellow"> ₦ {item.MINIMUM_REPAYMENT_DUE.toLocaleString(2)} </span></p>
                                                */}
                                            </div>
                                            <div className='repayment-box boxshadow'>
                                                <p className='creditdetails'>Bank Account Details</p>
                                                <p className='creditdetails1'>Bank Account Number : <span className="creditbold"> {"XXXXXX" + item.ACCOUNTNUMBER.slice(-4).repeat(1)} </span></p>
                                            </div>
                                            <div className='repayment-box boxshadow'>
                                                <p className='creditdetails'>Credit Card Settlement Account (for making repayments)</p>
                                                <p className='creditdetails1'>Credit Card Settlement Account : <span className="creditbold">{"XXXXXX" + item.CREDITACCOUNT.slice(-4).repeat(1)} </span></p>
                                            </div>
                                        </div>
                                    ))}
                                </div>}
                            </div>
                            <form className="repaymentform" onSubmit={handleSubmit(onSubmit)}>
                                <div>
                                    {creditStatusData && <div>
                                        {creditStatusData.map((item, i) => (
                                            <div>
                                                <div className="form-group groupmargin">
                                                    <label className="rpaymentpin">Please enter the amount you want to repay</label>
                                                    <div className="position-relative">
                                                        <span className="naira-symbol position-absolute">₦</span>
                                                        <input
                                                            type="text"
                                                            name="amount"
                                                            className="form-control pinreypmnt"
                                                            maxLength="13"
                                                            value={formattedValue}
                                                            onChange={handleInputChange}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="text-danger">
                                                    {errors.amount && (
                                                        errors.amount.type === "max" ? (
                                                            <span>{errors.amount.message}</span>
                                                        ) : (
                                                            <span>{errors.amount.message}</span>
                                                        )
                                                    )}
                                                </div>
                                            </div>
                                        ))}
                                    </div>}
                                </div>
                                {/* <p className='errormsgpin'>{errormsg}</p> */}
                                <div className='register-button'>
                                    <input type="submit" className="btn btn-warning nextbuttonpinRey" value="Next" />
                                </div>
                            </form>
                        </div>
                        <div className=''>
                        </div>
                    </div>
                </div>
            </div> :
                <div>
                    <div >
                        <div>
                            <div>
                                {creditStatusData && <div>
                                    {creditStatusData.map((item, i) => (
                                        <div>
                                            <div className='repayment-box'>
                                                <p className='creditdetails'>Credit Card Details</p>
                                                <div className='flowlist'>
                                                    <ul className='credit'>
                                                        <li className='creditdetails1'><span>DUO Credit Card Number</span> </li>
                                                        <li className='creditdetails1'><span>Credit Limit</span> </li>
                                                        <li className='creditdetails1'><span>Total Credit Card outstanding</span></li>
                                                        <li className='creditdetails1 avail'><span>Available Balance</span></li>
                                                    </ul>
                                                    <ul className='credit creditlistflow'>
                                                        <li className='creditdetails1'> <span className="creditbold"> : {item.CREDITCARD}</span></li>
                                                        <li className='creditdetails1'> <span className="creditbold"> : ₦ {commaSeperator(item.CREDIT_LIMIT, 2)}</span></li>
                                                        <li className='creditdetails1'><span className="creditbold"> : ₦ {commaSeperator(Math.abs(item.CREDIT_CARD_OUTSTANDINGBALANCE), 2)} </span> </li>
                                                        <li className='creditdetails1 avail'><span className="creditbold">:  ₦ {commaSeperator(availableBalance, 2)} </span></li>
                                                    </ul>
                                                </div>
                                                <div className='flowlist1'>
                                                    <ul className='creditminimum'>
                                                        <li className='duepayment'><span>Minimum Due Repayment</span> </li>
                                                    </ul>
                                                    <ul className='creditminimum'>
                                                        <li className='duepayment1'><span>: ₦ {commaSeperator(Math.abs(item.MINIMUM_REPAYMENT_DUE), 2)}</span> </li>
                                                    </ul>
                                                </div>
                                                {/* <p className='creditdetails2'>Minimum Due Repayment :<span className="creditboldyellow"> ₦ {item.MINIMUM_REPAYMENT_DUE.toLocaleString(2)} </span></p>
                                                */}
                                            </div>
                                            <div className='repayment-box boxshadow'>
                                                <p className='creditdetails'>Bank Account Details</p>
                                                <p className='creditdetails1'>Bank Account Number : <span className="creditbold"> {"XXXXXX" + item.ACCOUNTNUMBER.slice(-4).repeat(1)} </span></p>
                                            </div>
                                            <div className='repayment-box boxshadow'>
                                                <p className='creditdetails'>Credit Card Settlement Account (for making repayments)</p>
                                                <p className='creditdetails1'>Credit Card Settlement Account : <span className="creditbold">{"XXXXXX" + item.CREDITACCOUNT.slice(-4).repeat(1)} </span></p>
                                            </div>
                                        </div>
                                    ))}
                                </div>}
                            </div>
                            <form className="repaymentform" onSubmit={handleSubmit(onSubmit)}>
                                <div>
                                    {creditStatusData && <div>
                                        {creditStatusData.map((item, i) => (
                                            <div>
                                                <div className="form-group groupmargin">
                                                    <label className="rpaymentpin">Please enter the amount you want to repay</label>
                                                    <div className="position-relative">
                                                        <span className="naira-symbol position-absolute">₦</span>
                                                        <input
                                                            type="text"
                                                            name="amount"
                                                            className="form-control pinreypmnt"
                                                            maxLength="13"
                                                            value={formattedValue}
                                                            onChange={handleInputChange}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="text-danger">
                                                    {errors.amount && (
                                                        errors.amount.type === "max" ? (
                                                            <span>{errors.amount.message}</span>
                                                        ) : (
                                                            <span>{errors.amount.message}</span>
                                                        )
                                                    )}
                                                </div>
                                                <div className='pinerror'>{error}</div>
                                            </div>
                                        ))}
                                    </div>}
                                </div>
                                {/* <p className='errormsgpin'>{errormsg}</p> */}
                                <div className='register-button'>
                                    <input type="submit" className="btn btn-warning nextbuttonpinRey" value="Next" />
                                </div>
                            </form>
                        </div>
                        <div className=''>
                        </div>
                    </div>
                </div>
            }
            <br />

        </React.Fragment>
    )
}

export default RepaymentRequest;