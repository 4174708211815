import React, { useState, useEffect } from "react";
import CustomPinVerification from "../CustomePinVerification";
import { useLocation, Link, useHistory } from "react-router-dom";
import DUOlogo from '../../assets/whiteduologo.svg';
import axiosCall from "../../axios/axios";
import home from '../../assets/home-icon.svg';
import { Modal, Button } from 'react-bootstrap';
import Loader from '../Loader';
import ForgotPin from '../ForgotPin';

const VerifyMontraPin = (props) => {
  const location = useLocation();
  const myValue = new URLSearchParams(location.search).get('page');
  const [modalOpen, setModalOpen] = useState(false);
  const [errormsg, setErrormsg] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (props !== undefined && props.location !== undefined && props.location.state !== undefined) {
    }
  }, [props]);
  let history = useHistory();

  const handleUpdateOTP = (newOTP) => {
    if (newOTP?.length === 4) {
      setIsLoading(true);
      const body = {
        "ACCOUNTNUMBER": localStorage.getItem("AccountNo"),
        "PIN": newOTP
      }
      axiosCall.POST('/customer/mobilepinverify', body)
        .then((response) => {
          if (response.data.status === "200" && response.data.data.VALID_PIN === true) {
            if (myValue) {
              if (myValue !== 'CheckBalance') {
                history.push({
                  pathname: `/${myValue}`,
                });
              } else {
                axiosCall.POST('/customer/getduocustomerinfo', { "ACCOUNTNUMBER": localStorage.getItem("AccountNo") })
                  .then((response) => {
                    if (response.data.status === "200" && response.data.message === "SUCCESS") {
                      const data = response?.data?.data ?? {}
                      localStorage.setItem("isFrom", "CheckBalance");
                      localStorage.setItem("AvailableBalance", response?.data?.data.AVAILABLE_BALANCE);
                      history.push("/Home",
                        { isFrom: "CheckBalance", getinfo: data });
                    } else {
                      setErrormsg(response.data.errordata.message);
                      setModalOpen(true);
                      setIsLoading(false);
                    }
                    console.log("monu", response?.data?.data?.
                      AVAILABLE_BALANCE)

                  })
              }
            }
          }
          else {
            setErrormsg(response.data.data.MESSAGE);
            setModalOpen(true);
            setIsLoading(false);
          }
        }).catch(err => {
          console.log(err)
          setErrormsg("Please try After Sometime");
          setModalOpen(true);
          setIsLoading(false);
        });
    }
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleRedirect = () => {
    handleCloseModal();
  };

  const handleRedirectHome = () => {
    history.replace('/Home');
  };

  return (
    <div>
      {/* <div onClick={() => Link(-1)}>
        <img src={BACK_ARROW} alt="montra-log" className={styles.backarrow} />
      </div> */}

      {modalOpen && (
        <Modal show={modalOpen} handleClose={handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>Alert</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p className='modaltext'>{errormsg}</p>
            <hr className='hrline' />
          </Modal.Body>

          <Modal.Footer onClick={handleRedirect}>
            <Button variant="secondary" >
              OK
            </Button>
          </Modal.Footer>
        </Modal>
      )}


      <div className='HeaderBgverify'>
        <div className="duoheaderveryfi">
          <Link to="/Home"> <img className="duolog" src={DUOlogo} alt="logo" /></Link>
        </div>
        <p className='pinverifyheader'> PIN Verfication</p>
        <img className="duolog" src={home} alt="logo" onClick={handleRedirectHome} />
      </div>
      {isLoading ? <div>
        <Loader />
        <CustomPinVerification
          inputCount={4}
          onUpdateOTP={handleUpdateOTP}
          titleText={"Enter your Access Duo Pin"}
          description={true}
          clearCode={errormsg}
        />
        <div className='forgototp'>
          <ForgotPin />
        </div>
      </div> :
        <div>
          <CustomPinVerification
            inputCount={4}
            onUpdateOTP={handleUpdateOTP}
            titleText={"Enter your Access Duo Pin"}
            description={true}
            clearCode={errormsg}
          />
          <div className='forgototp'>
            <ForgotPin />
          </div>
        </div>
      }
    </div>
  );
};

export default VerifyMontraPin;
