import React from 'react';
import DUOlogo from '../assets/whiteduologo.svg';
import { Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import duomobile from '../assets/duomobile.svg';
import duocard from '../assets/duocard-yellow.svg';
import cardnumber from '../assets/cardnumber.svg';
import transactionhistory from '../assets/transactionhistory.svg';
import home from '../assets/home-icon.svg';
import remap from '../assets/remap.svg';


const ApplyDuoCard = (props) => {

    return (
        <React.Fragment>
            <Navbar className="navbar navbar-expand-lg navbar-dark bg-dark" bg="dark" expand="lg">
                <div className="navi">

                    <ul className='ulnavicon'>
                        <li className='linavigation imgduologo'> <img className="duosublogotopmain" src={DUOlogo} alt="logo" /></li>
                        <Link to="/Home">  <li className='linavigation'> <img className="duosublogotop " src={home} alt="logo" /></li> </Link>
                        <Link to="/VerifyDuoPin?page=VirtualCreditCard">  <li className='linavigation'> <img className="duosublogotop " src={duomobile} alt="logo" /></li> </Link>
                        <Link to="/VerifyDuoPin?page=TransactionHistory">   <li className='linavigation'> <img className="duosublogotop " src={transactionhistory} alt="logo" /></li> </Link>
                        <Link to="/RepaymentRequest">  <li className='linavigation'> <img className="duosublogotop " src={duocard} alt="logo" /></li></Link>
                        <Link to="/VerifyDuoPin?page=Remap">  <li className='linavigation remaplogo'> <img className="duosublogotop " src={remap} alt="logo" /></li></Link>
                        {/* <Link to="/VerifyDuoPin?page=ApplyDuoCard">  <li className='linavigation'> <img className="duosublogotop " src={cardnumber} alt="logo" /></li></Link>
                        */}
                    </ul>
                </div>
            </Navbar>
            <p className='homesummery'>Repayment Request</p>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-3'>
                    </div>
                    <div className='col-md-6'>
                        <p className="resuccess">Your request for repayment of <b> ₦ {props.location.state.amountValue}</b> towards your credit outstanding has been submitted successfully. Please check transaction detail for the confirmation of the repayment.</p>
                    </div>
                    <div className='col-md-3'>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default ApplyDuoCard;