import React, { useEffect, useMemo, useState } from 'react';
import Header2 from '../Header2';
import duomobile from '../assets/duomobile-black.svg';
import duocard from '../assets/duocard-black.svg';
import linkimg from '../assets/link.svg';
import { Link, useLocation } from 'react-router-dom';
import transactionhistory from '../assets/transactionhistory-black.svg';
import Charts from './charts';
import Chartsloader from './chartsloader'
import ChartsAB from './chartsAB';
import CardType from './CardType';
import { Modal, Button } from 'react-bootstrap';
import { commaSeperator } from './CommaSeprator';

const Home = (props) => {
    const location = useLocation();
    const [creditStatusData, setCreditStatusData] = useState();
    const currentDate = new Date();
    const currentMonth = currentDate.toLocaleString('default', { month: 'short' });
    const currentYear = currentDate.getFullYear();
    const [modalOpen, setModalOpen] = useState(false);
    const [availableBal, setAvailableBal] = useState();
    const [myvalue, setMyvalue] = useState();
    const [getinfoData, setGetinfoData] = useState()
    const { isFrom, getinfo } = location.state || {};
    useEffect(() => {
        const { isFrom, getinfo } = location.state || {};
        setGetinfoData(getinfo);
        setMyvalue(localStorage.getItem("isFrom"));
    }, [location]);
    useEffect(() => {
        var Status = JSON.parse(localStorage.getItem("StatusData"));
        setCreditStatusData(Status);
        var data = JSON.parse(localStorage.getItem("customeridData"));
        setAvailableBal(data);

    }, []);

    useEffect(() => {
        if (props !== undefined && props.location !== undefined && props.location.state !== undefined && props.location.state.isactivity !== undefined && props.location.state.isactivity === true) {
            setModalOpen(true);
        }
    }, [props]);
    useEffect(() => {
        window.addEventListener('beforeunload', handleCloseModal);
        return () => {
            window.removeEventListener('beforeunload', handleCloseModal);
        }
    }, []);
    const handleCloseModal = () => {
        setModalOpen(false);
    };
    const handleRedirect = () => {
        handleCloseModal();
    };
    const checkRespcodeValue = useMemo(() => {
        if (localStorage.getItem("respcode") === "70" || localStorage.getItem("respcode") === "71") {
            return true
        } else {
            return false
        }
    }, [])
    return (
        <React.Fragment>
            <Header2 />
            <div>
                {modalOpen && (
                    <Modal show={modalOpen} handleClose={handleCloseModal}>
                        <Modal.Header closeButton>
                            <Modal.Title>Alert</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p className='modaltext'>Because of inactivity you have been redirected to home screen</p>
                            <hr className='hrline' />
                        </Modal.Body>

                        <Modal.Footer onClick={handleRedirect}>
                            <Button variant="secondary" >
                                OK
                            </Button>
                        </Modal.Footer>
                    </Modal>
                )}
            </div>
            <div>
                <p className='homesummery'>Credit Summary</p>
                <CardType />
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-3'>
                        </div>
                        <div className='col-md-6'>
                            <div className=''>
                                <div className='divparent'>
                                    <div className='homecharts'>
                                        <div id="chart">
                                            <Chartsloader />
                                            {/* {(() => {
                                                const availableBalance = localStorage.getItem("AvailableBalance");
                                                const isFrom = localStorage.getItem("isFrom");
                                                console.log("logvalue", isFrom);
                                                if (myvalue === "CheckBalance") {
                                                    return <ChartsAB />;
                                                } else {
                                                    return <Chartsloader />;
                                                }
                                            })()} */}
                                        </div>
                                        <div className='container'>
                                            <div>
                                                <div className='chartstatus'>
                                                    <div className='status'>₦ 0.00</div>
                                                    <div className='status1'>Activation Status *
                                                        {(localStorage.getItem("respcode") === "70" || localStorage.getItem("respcode") === "71") ?
                                                            (
                                                                <div className='statuscirclegreen'>
                                                                </div>
                                                            )
                                                            : (
                                                                <div className='statuscircle'>
                                                                </div>
                                                            )
                                                        }

                                                    </div>
                                                    <div className='status2'>₦  {commaSeperator(availableBal?.ACTUAL_LIMIT ?? '0')}</div>
                                                    {/* {(() => {
                                                        const actuallimit = availableBal?.ACTUAL_LIMIT ?? '';
                                                        if (String(actuallimit) === "0" || String(actuallimit) === "0.0") {
                                                            return (
                                                                <div className='status2'>₦  {commaSeperator(availableBal?.CREDIT_LIMIT ?? '0')}</div>
                                                            )
                                                        }
                                                        else if (String(actuallimit) !== "0" || String(actuallimit) !== "0.0") {
                                                            return (
                                                                <div className='status2'>₦  {commaSeperator(availableBal?.ACTUAL_LIMIT ?? '0')}</div>
                                                            )
                                                        }
                                                    })()} */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        checkRespcodeValue ?
                                            (
                                                <div className='container activetext'>
                                                    <p className="remapdebit">
                                                        “Green” status means that you have been Activated and therefore you can start making DTS transaction requests now
                                                    </p>
                                                </div>
                                            )
                                            :
                                            (
                                                <div className='container activetext'>
                                                    <p className="remapdebit">
                                                        *Orange* status means that your Activation Request is still being attended to. This may take up to 24 hours time before you making transaction requests
                                                    </p>

                                                </div>
                                            )
                                    }
                                </div>
                            </div>
                            <div>
                                <div className="containerBox">
                                    <div className="item ">
                                        <p className="toptex">Payment Due Date</p>
                                        {/* <p className="itemend">15-{currentMonth}-{currentYear}</p> */}
                                        {(() => {
                                            if (Math.abs(creditStatusData?.MINIMUM_REPAYMENT_DUE) > 0) {
                                                if (currentDate < 15) {
                                                    return (
                                                        <p className="itemend">15-{currentMonth}-{currentYear}</p>
                                                    );
                                                } else {
                                                    return (
                                                        <p className="itemend">Immediate</p>
                                                    );
                                                }
                                            } else {
                                                return (
                                                    <p className="itemend">Not Due</p>
                                                );
                                            }
                                        })()}
                                    </div>
                                    <div className="item1">
                                        <p className="toptex">Minimum Payment Due</p>
                                        <p className="itemend">₦ {commaSeperator(Math.abs(creditStatusData?.MINIMUM_REPAYMENT_DUE), 2)}</p>
                                    </div>
                                    <div className="item2">
                                        <p className="toptex">Card Status</p>
                                        {(() => {
                                            const actuallimit = availableBal?.ACTUAL_LIMIT ?? '';
                                            if (creditStatusData?.CARDSTATUS === 1 || creditStatusData?.CARDSTATUS === 10 || creditStatusData?.CARDSTATUS === 11) {
                                                return (
                                                    <p className="itemend1">Active </p>
                                                )
                                            } else if (creditStatusData?.CARDSTATUS === 4 || creditStatusData?.CARDSTATUS === 9) {
                                                return (
                                                    <p className="itemend1">Inactive</p>
                                                )
                                            }
                                            else if (creditStatusData?.CARDSTATUS === 0 && actuallimit === 0) {
                                                return (
                                                    <p className="itemend1">Inactive</p>
                                                )
                                            }
                                            else {
                                                return (
                                                    <p className="itemend1">Active</p>
                                                )
                                            }
                                        })()}

                                    </div>
                                    <div className="item3">
                                        <p className="toptex">Payment Status</p>
                                        <p className="itemend1">Late by  {creditStatusData?.DAYS_OVERDUE} Days</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='container homemenues'>
                            <div className='iconwithtextop'>
                                <div className='iconwithtext1'>
                                    <img className="duologicon" src={duomobile} alt="logo" />
                                </div>
                                <div className=''>
                                    <Link to="/VerifyDuoPin?page=VirtualCreditCard" className='iconwithtext2'>Virtual Credit Card </Link>
                                </div>
                            </div>
                            <hr className="borderhome" />
                            <div className='iconwithtextop'>
                                <div className='iconwithtext1'>
                                    <img className="duologicon" src={transactionhistory} alt="logo" />
                                </div>
                                <div className=''>
                                    <Link to="/VerifyDuoPin?page=TransactionHistory" className='iconwithtext2'> Transaction History </Link>
                                </div>
                            </div>
                            <hr className="borderhome" />
                            <div className='iconwithtextop'>
                                <div className='iconwithtext1'>
                                    <img className="duologicon" src={duocard} alt="logo" />
                                </div>
                                <div className=''>
                                    <Link to="/VerifyDuoPin?page=RepaymentRequest" className='iconwithtext2'> Repayment Request </Link>
                                </div>
                            </div>
                            <hr className="borderhome" />
                            <div className='iconwithtextop'>
                                <div className='iconwithtext1'>
                                    <img className="duologicon" src={linkimg} alt="logo" />
                                </div>
                                <div>
                                    <Link to="/VerifyDuoPin?page=Remap" className='iconwithtext2'>   View / Update Linked Debit Cards (DTS) </Link>
                                </div>
                            </div>
                            <br />
                            {/* <hr className="borderhome" />
                                 <div className='iconwithtextop'>
                                     <div className='iconwithtext1'>
                                         <img className="duologicon" src={cardnumber} alt="logo" />
                                     </div>
                                     <div className=''>
                                         <Link to="/PinVerification?page=ApplyDuoCard" className='iconwithtext2'> Apply for DUO Card </Link>
                                     </div>
                                 </div> */}
                            {/* <hr className="borderhome" /> */}
                        </div>
                        <div className='col-md-3'>
                        </div>
                    </div>
                    <div className='col-md-3'>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )

}
export default Home;