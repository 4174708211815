
export const removeCommaSeperator = (value) => {
  const newValue = value.toString();
  const commaRegex = /,/;
  if (commaRegex.test(newValue)) {
    return newValue.replace(',', ''); //replace the coma with the empty text
  }
  return value;
};

export const CommonAmountFormat = (value, decimalLength = 0) => {
  if (value) {
    const convToString = String(value);
    const result = parseFloat(convToString)?.toFixed?.(decimalLength);
    if (result) {
      return String(result);
    }
    return value;
  }
  return `0`;
};

export const commaSeperator = (x, decimalLength = 0) => {
  if (x) {
    //checking the value is not null
    const removedComma = removeCommaSeperator(x);
    let convValue = CommonAmountFormat(removedComma, decimalLength);
    const regex = /^(\d+)?([.]?\d{0,2})?$/; //create reguler expresion with last 2 digit dot
    if (!regex.test(convValue)) {
      // Checking reguler expression
      const newValue = convValue
        .replace(/[^\d.]/g, '') // remove more than two digits after decimal point
        .replace(/^(\d*\.?\d{0,2})?.*/g, '$1'); // Allow only one decimal point
      convValue = newValue;
    }
    let data = convValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','); // add comma in string and return the value
    // console.log('processed: ' + data);
    return data;
  } else {
    return `0`;
  }
};