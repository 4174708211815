import axios from 'axios';

const baseUrl = 'https://duoapi.duocard.org';


// const baseUrl = 'https://duoapiqa.duocard.org/';

let contentJSON = {
    'content-type': 'application/json;charset=UTF-8',
    'Access-Control-Allow-Origin': '*',
    'source': 'webview',
};


const axiosCall = {

    GET: async function (url, header) {
        const headers = header;
        let answer = await axios.get(baseUrl + url, { headers: headers });
        return answer;
    },

    POST: async function (url, bodyData, header) {
        contentJSON['clientcodeencr'] = localStorage.getItem("clientCodeencr");
        contentJSON['Authorization'] = 'Bearer ' + localStorage.getItem("longtoken");
        contentJSON['userId'] = localStorage.getItem("userId");
        contentJSON['webviewauthorization'] = localStorage.getItem("webviewauthorization");
        contentJSON['webviewuserid'] = localStorage.getItem("webviewuserid");
        const headers = contentJSON;
        const bodydata = bodyData;
        return await axios.post(baseUrl + url, bodydata, { headers: headers });
    },

    PUT: async function (url, body) {
        const config = { headers: contentJSON };
        return await axios.put(baseUrl + url, body, config);

    }
}

export default axiosCall;