
import React, { } from 'react';
import './App.css';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import Pin from './component/Pin';
import RegistrationPage1 from './component/RegistrationPage1';
import RegistrationPage2 from './component/RegistrationPage2';
import RegistrationPage3 from './component/RegistrationPage3';
import RegistrationPage4 from './component/RegistrationPage4';
import RegistrationPage from './component/RegistrationPage';
import VirtualCreditCard from './component/VirtualCreditCard';
import Home from './component/Home';
import Success from './component/Success';
import Header from './Header';
import Header2 from './Header2';
import RepaymentRequest from './component/RepaymentRequest';
import TransactionHistory from './component/TransactionHistory';
import Remap from './component/Remap';
import RemapDone from './component/RemapDone';
import RemapSuccess from './component/RemapSuccess';
import RemapSuccess1 from './component/RemapSuccess1';
import ApplyDuoCard from './component/ApplyDuoCard';
import ApplyDuoCard1 from './component/ApplyDuoCard1';
import ApplyDuoCard3 from './component/ApplyDuoCard3';
import ForgotPin from './component/ResendPin';
import ForgotPinSetup from './component/ForgotPinSetup';
import ResendPin from './component/ResendPin';
import PinSetup from './component/PinSetup';
import RePaymentPinVerification from './component/RePaymentPinVerification'
import VerifyDuoPin from './component/VerifyDuoPin';
import Unauthorised from './component/Forbidden';
import AppHome from "./component/AppHome";

const App = () => {

  return (
    <React.Fragment>
      <Router>
        <Switch>
          <Route path="/" exact component={AppHome} />
          <Route exact path="/RegistrationPage" component={RegistrationPage} />
          <Route path="/Pin" component={Pin} />
          <Route path="/Home" exact component={Home} />
          <Route exact path="/RegistrationPage1" component={RegistrationPage1} />
          <Route exact path="/RegistrationPage2" component={RegistrationPage2} />
          <Route path="/RegistrationPage3" exact component={RegistrationPage3} />
          <Route path="/RegistrationPage4" exact component={RegistrationPage4} />
          <Route exact path="/VirtualCreditCard" component={VirtualCreditCard} />
          <Route exact path="/Header" component={Header} />
          <Route exact path="/Header2" component={Header2} />
          <Route exact path="/RepaymentRequest" component={RepaymentRequest} />
          <Route exact path="/TransactionHistory" component={TransactionHistory} />
          <Route exact path="/Remap" component={Remap} />
          <Route exact path="/RemapSuccess" component={RemapSuccess} />
          <Route exact path="/RemapSuccess1" component={RemapSuccess1} />
          <Route exact path="/RemapDone" component={RemapDone} />
          <Route exact path="/ApplyDuoCard" component={ApplyDuoCard} />
          <Route exact path="/ApplyDuoCard1" component={ApplyDuoCard1} />
          <Route exact path="/ApplyDuoCard3" component={ApplyDuoCard3} />
          <Route exact path="/ForgotPin" component={ForgotPin} />
          <Route exact path="/ForgotPinSetup" component={ForgotPinSetup} />
          <Route exact path="/ResendPin" component={ResendPin} />
          <Route exact path="/PinSetup" component={PinSetup} />
          <Route exact path="/Success" component={Success} />
          <Route exact path="/Forbidden" component={Unauthorised} />
          <Route exact path="/RePaymentPinVerification" component={RePaymentPinVerification} />
          <Route exact path="/VerifyDuoPin" component={VerifyDuoPin} />
        </Switch>
      </Router>
    </React.Fragment>
  );
}


export default App;
